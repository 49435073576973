<template>
  <div class="content">
    <van-nav-bar title="下载中心" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="wap-nav" size="18" @click="flag = !flag" />
      </template>
    </van-nav-bar>
    <div class="listbox">
      <div class="title">
        <div>导出列表</div>
        <div>
          <img
            v-if="flag && deletelist.length != list.length"
            src="~@/assets/images/distributionIndex/selecetcheck.png"
            @click="allselect()"
            alt=""
          />
          <img
            v-if="flag && deletelist.length == list.length"
            src="~@/assets/images/distributionIndex/selectchecked.png"
            @click="allselect()"
            alt=""
          />
        </div>
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="list-item" v-for="item in list" :key="item.id">
          <div class="item-left">
            <div class="excelpng">
              <img src="~@/assets/images/distributionIndex/excel.png" alt="" />
            </div>
            <div class="excelpng-text">
              <div>{{ name(item.url) }}</div>
              <div>{{ item.filesize }}</div>
            </div>
          </div>
          <div class="item-right" v-if="!flag">
            <div class="left-r" @click="download(item.url)">
              <img
                src="~@/assets/images/distributionIndex/downloadD.png"
                alt=""
              />
              <div style="margin-top: 10px">已下载</div>
            </div>
            <div class="right-r" @click="copy(item.url)">
              <img
                src="~@/assets/images/distributionIndex/copyaddress.png"
                alt=""
              />
              <div style="margin-top: 10px">复制文件地址</div>
            </div>
          </div>
          <div class="item-right2" v-if="flag">
            <img
              v-if="deletelist.indexOf(item.id) == -1 ? true : false"
              src="~@/assets/images/distributionIndex/selecetcheck.png"
              alt=""
              @click="selectDelete(item.id)"
            />
            <img
              v-if="deletelist.indexOf(item.id) == -1 ? false : true"
              src="~@/assets/images/distributionIndex/selectchecked.png"
              alt=""
              @click="qselectDelete(item.id)"
            />
          </div>
        </div>
      </van-list>
    </div>

    <div class="delete" v-if="flag" @click="deleteExport()">删除</div>
  </div>
</template>

<script>
import { exportList, exportDelete } from "@/untils/distubritionIndex.js";
export default {
  data() {
    return {
      page: 0,
      list: [],
      flag: false, //选择
      deletelist: [],
      loading: false,
      finished: false,
    };
  },
  computed: {},
  mounted() {
    // this.getlist();
  },
  methods: {
    onClickLeft() {
      //   Toast('返回');
      this.$router.go(-1);
      //   console.log(123);
    },
    deleteExport() {
      let data = {
        id: this.deletelist.join(","),
        user_id: JSON.parse(sessionStorage.getItem("UserInfo")).id,
      };
      // console.log(data);
      exportDelete({ data }).then((res) => {
        // console.log(res);
        this.page = 0;
        this.list = [];
        this.finished = false;
        this.onLoad();
      });
    },

    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.getlist();
        // 加载状态结束

        // 数据全部加载完成
      }, 1000);
    },

    //全选
    allselect() {
      if (this.deletelist.length == this.list.length) {
        this.deletelist = [];
      } else {
        this.deletelist = [];
        this.list.forEach((item) => {
          this.deletelist.push(item.id);
        });
      }
    },

    qselectDelete(id) {
      this.deletelist.splice(this.deletelist.indexOf(id), 1);
      //  console.log(this.deletelist.indexOf(id));
    },
    selectDelete(id) {
      this.deletelist.push(id);
    },
    download(url) {
      window.location = "https://connect.ydbimg.com/" + url;
    },
    copy(url) {
      let data = "https://connect.ydbimg.com/" + url;
      this.$copyText(data).then((res) => {
        this.$toast("复制成功");
      });
    },
    name(url) {
      // console.log( );
      return url.split("/")[2].substring(0, 12) + ".xls";
    },
    getlist() {
      let data = {
        userMobile: JSON.parse(sessionStorage.getItem("UserInfo")).mobile,
        page: ++this.page,
      };
      if (this.finished) return;
      exportList({ data }).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
        //   console.log(res.data.data.data.length);
          this.loading = false;
          if (res.data.data.data.length == 0) {
            this.finished = true;
          }
          this.list = [...this.list, ...res.data.data.data];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
}
.delete {
  font-size: 16px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  line-height: 43px;
  width: 217px;
  height: 43px;
  border-radius: 21px;
  background: linear-gradient(
    301deg,
    #ff1e1e 1%,
    #ff1e1e 1%,
    #ff7676 112%,
    #ff7676 116%
  );
}
.listbox {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 100px;
  .title {
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: space-between;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .list-item {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: flex-end;
    .item-left {
      width: 50%;
      display: flex;
      .excelpng {
        img {
          width: 32px;
          height: 35px;
        }
      }
      .excelpng-text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 10px;
      }
    }
    .item-right2 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .item-right {
      width: 50%;

      display: flex;
      justify-content: flex-end;
      .left-r {
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-right: 20px;
        img {
          width: 20px;
          height: 16px;
        }
      }
      .right-r {
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
</style>